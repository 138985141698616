import React, {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "../App.css";

import {DataGrid, GridColumns, ukUA} from '@mui/x-data-grid';
import {createTheme, darken, lighten, ThemeProvider} from '@mui/material/styles';
import CheckboxService from "../services/checkbox.service";

import Badge from '@mui/material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';

import Popover from '@mui/material/Popover';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import DoorBackIcon from '@mui/icons-material/DoorBack';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CreateIcon from '@mui/icons-material/Create';

import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import {useSnackbar} from 'notistack';
import {FormControlLabel, Skeleton, Switch} from "@mui/material";

function getCurrentDate() {
    var today = new Date(Date.now())

    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()

    today = yyyy + '-' + mm + '-' + dd

    return today;
}

const LoadingSkeleton = () => (
    <Box
        sx={{
            height: "max-content"
        }}
    >
        {[...Array(10)].map((_) => (
            <Skeleton variant="rectangular" sx={{my: 2, mx: 1}}/>
        ))}
    </Box>
);

const rows = [
    {
        id: "1",
        orderid: "",
        payment: "",
        products: [{product_name: '', quantity: '', price: ''}],
        productsresale: [{product_name: '', quantity: '', price: ''}],
        ttn: "",
        phone: ""
    },
];

function hasProgress(state) {
    return state === 0;
}

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex', paddingRight: 3}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingRight: 3,
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize={10}
                    hidden={hasProgress(Math.round(props.value))}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const BoardChecks = () => {
    const [progress, setProgress] = React.useState(0);

    const {enqueueSnackbar} = useSnackbar();

    const [pageCount, setPageCount] = React.useState(1);

    const [sendSMS, setSendSMS] = React.useState(false);

    const [operationsList, setOperationsList] = useState(rows);

    const {user: currentUser} = useSelector((state) => state.auth);

    let defaultTab;

    if (currentUser.premissions.includes("CHECKS_YS")) {
        defaultTab = 0;
    } else {
        defaultTab = 5;
    }

    const [tab, setTab] = React.useState(defaultTab);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [inProgress, setInProgress] = React.useState(false)

    const [showYSChecks, setShowYSChecks] = useState(false);
    const [showFBChecks, setShowFBChecks] = useState(false);

    useEffect(() => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
        setTab(tab);
        setOperationsList(operationsList)
        setProgress(progress)

        if (currentUser) {
            setShowYSChecks(currentUser.premissions.includes("CHECKS_YS"));
            setShowFBChecks(currentUser.premissions.includes("CHECKS_FAM"));
        } else {
            setShowYSChecks(false);
            setShowFBChecks(false);
        }

    }, [rowsPerPage, page, tab, operationsList, progress, currentUser]);

    useEffect(() => {
        if (currentUser.premissions.includes("CHECKS_YS")) {
            loadOrdersForCheck("245");
        } else {
            loadOrdersForCheck("259"); //76
        }
    }, []);

    const handleCreateCheck = async (event) => {
        event.preventDefault();

        setProgress(0)
        setInProgress(true)

        let orders = operationsList.filter(order => selectionModel.includes(order.id));
        const orders_count = orders.length;
        const progressbar_count = 100 / orders_count

        await orders.forEach(function (order, idx, array) {
            setTimeout(async () => {
                await CheckboxService.createSell(tab, sendSMS, order)
                    .then((response) => {
                        const parsed_response = Object.values(response)[0];
                        if (parsed_response.startsWith('Чек стоворено')) {
                            if ( tab === 6 || tab === 7) {
                                CheckboxService.changeStatus(order.id, "72").finally(() => {
                                    setOperationsList(state => state.filter(ord => ord.id !== order.id));
                                    setProgress(prevState => prevState + progressbar_count)

                                    if (idx === array.length - 1) {
                                        enqueueSnackbar(`Чеків ${idx + 1} створено`, {variant: "success"});
                                        setInProgress(() => false)
                                    }
                                });
                            } else {
                                CheckboxService.changeStatus(order.id, "108").finally(() => {
                                    setOperationsList(state => state.filter(ord => ord.id !== order.id));
                                    setProgress(prevState => prevState + progressbar_count)

                                    if (idx === array.length - 1) {
                                        enqueueSnackbar(`Чеків ${idx + 1} створено`, {variant: "success"});
                                        setInProgress(() => false)
                                    }
                                });
                            }

                            setPageCount(prevState => prevState - 1);

                        } else enqueueSnackbar(parsed_response, {variant: "error"});

                    })
                    .catch((response) => {
                        enqueueSnackbar(`${Object.values(response)[0]}`, {variant: "error"});
                    });
            }, idx * 1100);
        })
    }

    const handleSendSMS = (event) => {
        setSendSMS(event.target.checked)
    }

    const handleOpenShift = (event) => {
        event.preventDefault();
        CheckboxService.openShifts(tab)
            .then((response) => {
                const parsed_response = Object.values(response)[0];
                if (parsed_response.startsWith('Зміну відкрито')) {
                    enqueueSnackbar(`Зміну відкрито`, {variant: "success"});
                } else {
                    enqueueSnackbar(parsed_response, {variant: "error"});
                }
            })
            .catch((response) => {
                enqueueSnackbar(`${Object.values(response)[0]}`, {variant: "error"});
            });
    }

    const handleCloseShift = (event) => {
        event.preventDefault();
        CheckboxService.closeShifts(tab)
            .then((response) => {
                const parsed_response = Object.values(response)[0];
                if (parsed_response.startsWith('Зміну закрито')) {
                    enqueueSnackbar(`Зміну закрито`, {variant: "success"});
                } else {
                    enqueueSnackbar(parsed_response, {variant: "error"});
                }
            })
            .catch((response) => {
                enqueueSnackbar(`${Object.values(response)[0]}`, {variant: "error"});
            });
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setPage(0);
        setOperationsList(rows);
        setRowsPerPage(rowsPerPage);
        setProgress(0);

        switch (newValue) {
            case 0: {
                loadOrdersForCheck("245");
                break;
            }
            case 1: {
                loadOrdersForCheck("277");
                break;
            }
            case 2: {
                loadOrdersForCheck("246");
                break;
            }
            case 3: {
                loadOrdersForCheck("244");
                break;
            }
            case 4: {
                loadOrdersForCheck("308");
                break;
            }
            case 5: {
                loadOrdersForCheck("259"); //259
                break;
            }
            case 6: {
                loadOrdersForCheck("77");
                break;
            }
            case 7: {
                loadOrdersForCheck("92");
                break;
            }
            case 8: {
                loadOrdersForCheck("362");
                break;
            }
            case 9: {
                loadOrdersForCheck("363");
                break;
            }
            case 10: {
                loadOrdersForCheck("378");
                break;
            }
            case 11: {
                loadOrdersForCheck("385");
                break;
            }
            case 12: {
                loadOrdersForCheck("390");
                break;
            }
            case 13: {
                loadOrdersForCheck("394");
                break;
            }
        }
    };

    const handleChangeRowsPerPage = (newPage) => {
        setRowsPerPage(newPage)
        setPage(0);
    };

    const columns: GridColumns = [
        {
            field: 'id',
            headerName: "ID",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 80,
            flex: 1
        },
        {
            field: 'orderid',
            headerName: "№ Замовлення",
            maxWidth: 130,
            headerAlign: 'center',
            align: 'center',
            flex: 2
        },
        {
            field: 'payment',
            headerName: "Платіж",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 150,
            flex: 3
        },
        {
            field: 'products',
            headerName: "Основні товари",
            headerAlign: 'center',
            valueGetter: (params) => {
                if (params.row.products === undefined || params.row.products[0].product_name === '') return 'Завантаження замовлень з CRM'
                let prodList = ''
                params.row.products.forEach(product => {
                    prodList += product.product_name + " " + product.quantity + "шт. " + product.price + " грн. \n"
                })
                return prodList
            },
            align: 'left',
            minWidth: 40,
            flex: 4
        },
        {
            field: 'productsresale',
            headerName: "Допродажі",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 200,
            flex: 5,
            valueGetter: (params) => {
                if (params.row.productsresale == null || params.row.productsresale[0].product_name === '') return ''
                let prodList = ''
                params.row.productsresale.forEach(product => {
                    prodList += product.product_name + " " + product.quantity + "шт. " + product.price + " грн. \n"
                })
                return prodList
            },
        },
        {
            field: 'ttn',
            headerName: "ТТН",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 160,
            flex: 6
        },
        {
            field: 'phone',
            headerName: "Телефон",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 160,
            flex: 6
        }
    ];

    const [selectionModel, setSelectionModel] = React.useState([]);

    const loadOrdersForCheck = (status) => {
        setInProgress(true)
        const options = []
        CheckboxService.getOrdersByStatus(getCurrentDate(), status)
            .then((response) => {
                loadTableData(response, options, status)
            })
            .catch(() => {
            });
    };

    const loadTableData = (response, options, status) => {
        CheckboxService.getOrdersByStatusPage(status)
            .then((resp) => {
                Object.values(response.data.array).forEach((order) => {
                    let targetId = order.id;
                    let pageOrder = resp.data.find((order) => order.id === targetId);
                    let phone = pageOrder ? pageOrder.phone : '';

                    // Добавляем префикс "38" к номеру телефона, если он не начинается с "38"
                    if (phone && !phone.startsWith('38')) {
                        phone = '38' + phone;
                    }

                    options.push({
                        id: order.id,
                        orderid: order.order_id,
                        payment: order.payment,
                        delivery: order.delivery,
                        products: order.products,
                        productsresale: order.products_resale,
                        ttn: order.ttn,
                        additional_4: order.additional_4,
                        phone: phone
                    });
                });

                setPageCount(options.length);
                setOperationsList(options);
                setInProgress(false);
            })
            .catch(() => {});
    };


    const myTheme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    row: {
                        "&.Mui-selected": {
                            backgroundColor: "rgba(248,228,6,0.5)",
                            "&:hover": {
                                backgroundColor: "rgba(248,228,6,1)",
                            }
                        }
                    }
                }
            }
        }
    });

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <ThemeProvider theme={myTheme}>
            <Box
                sx={{
                    maxWidth: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 4,
                    wordWrap: 'break-word',
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
                            "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
                            '&:hover:not(.Mui-selected)': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor("rgba(101,99,99,0.5)", theme.palette.mode),
                            },
                        },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(52,58,64,1)",
                        color: "rgba(255,255,255,1)",
                        fontSize: 16
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "rgba(52,58,64,1)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        "& .MuiTablePagination-selectLabel": {
                            paddingTop: 1.4
                        },
                        "& .MuiTablePagination-displayedRows": {
                            paddingTop: 1.4
                        },
                        "& .MuiDataGrid-selectedRowCount": {
                            color: 'white',
                        }
                    },
                    "& .MuiTablePagination-toolbar": {
                        color: 'white',
                    },
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Tab disabled={!showYSChecks} label="Віктор"/>
                        <Tab disabled={!showYSChecks} label="Сергій"/>
                        <Tab disabled={!showYSChecks} label="Тетяна"/>
                        <Tab disabled={!showYSChecks} label="Віталій"/>
                        <Tab disabled={!showYSChecks} label="Люба"/>
                        <Tab disabled={!showFBChecks} label="Алина С"/>
                        <Tab disabled={!showFBChecks} label="Алина А"/>
                        <Tab disabled={!showFBChecks} label="Ярослав"/>
                        <Tab disabled={!showYSChecks} label="Богдан"/>
                        <Tab disabled={!showYSChecks} label="Андрей"/>
                        <Tab disabled={!showYSChecks} label="Лілія"/>
                        <Tab disabled={!showYSChecks} label="Оксана"/>
                        <Tab disabled={!showYSChecks} label="АНДРІЙ І"/>
                        <Tab disabled={!showYSChecks} label="ЗАІКА В"/>
                    </Tabs>

                    <FormControlLabel control={<Switch checked={sendSMS} onChange={handleSendSMS} />} label="CМС" />
                    <CircularProgressWithLabel value={progress}/>
                    <Badge badgeContent={selectionModel.length}
                           color="primary"
                           onClick={handleClick}
                           sx={{mt:1}}
                    >
                        <SettingsIcon sx={{"&:hover": {color: "blue"}}} fontSize="large" color="action"/>
                    </Badge>

                    <Popover
                        id={id}
                        open={open && !inProgress}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuList>
                            <MenuItem onClick={handleOpenShift}>
                                <ListItemIcon>
                                    <MeetingRoomIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Відкрити зміну</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleCreateCheck}>
                                <ListItemIcon>
                                    <CreateIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Створити чеки</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleCloseShift}>
                                <ListItemIcon>
                                    <DoorBackIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Закрити зміну</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Popover>
                </Box>

                <div style={{width: '100%', backgroundColor: 'white'}}>
                    <div>
                        <DataGrid checkboxSelection={true}
                                  rows={inProgress ? [] : operationsList}
                                  columns={columns}
                                  rowCount={pageCount}
                                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                  page={page}
                                  onPageChange={(newPage) => setPage(newPage)}
                                  rowsPerPage={rowsPerPage}
                                  pageSize={rowsPerPage}
                                  onPageSizeChange={handleChangeRowsPerPage}
                                  autoHeight={true}
                                  showCellRightBorder={true}
                                  localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                                  onSelectionModelChange={(newSelectionModel) => {
                                      setSelectionModel(newSelectionModel);
                                  }}
                                  selectionModel={selectionModel}
                                  components={{
                                      LoadingOverlay: LoadingSkeleton
                                  }}
                                  loading={inProgress}
                                  sx={{minHeight: '42vh'}}
                        />
                    </div>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default BoardChecks;